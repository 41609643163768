<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-row>
      <v-col cols="10">
        <h1>Список товаров из ShoqDoner</h1>
        <v-btn
          class="ma-2"
          color="green"
          @click="isShowCart = true"
        >
          <v-icon style="margin-right: 8px">mdi-cart</v-icon>
          9000 тг
        </v-btn>
        <v-btn
          class="ma-2"
          color="green"
          @click="createDialog"
        >
          <v-icon style="margin-right: 8px">mdi-plus</v-icon>
          Добавить товар
        </v-btn>
      </v-col>
      <v-col cols="10">
        <div class="text-center">
          <v-btn
            class="ma-2"
            color="secondary"
          >
            Комбо
          </v-btn>

          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
          >
            Бургер
          </v-btn>
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
          >
            Донер
          </v-btn>
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
          >
            Напитки
          </v-btn>
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
          >
            Еще что-то
          </v-btn>
        </div>
      </v-col>
      <v-row style="padding: 16px">
        <v-card
          v-for="goods in goodsList"
          :key="goods.id"
          class="mx-4"
          max-width="150"
        >
          <v-img
            class="black--text align-end"
            height="100px"
            :src="`https://api.shoq.kz/storage/items/${goods.img_source}`"
          >
          </v-img>
          <v-card-title color="orange text-md-8 mb-4">
            {{ goods.ru_name }}
          </v-card-title>
          <v-card-subtitle class="pb-0"> Цена {{ goods.price }} тг </v-card-subtitle>

          <v-card-actions>
            <v-btn
              color="orange pb-0"
              small
              fab
              @click="editDialog(goods)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              color="red pb-0"
              small
              fab
              @click="deleteDialog(goods)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5"> Товар </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              v-model="valid"
              ref="form"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.ru_name"
                    label="Название"
                    :error-messages="errors.ru_name"
                    @input="errors.ru_name = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.ru_description"
                    label="Описание"
                    :error-messages="errors.ru_description"
                    @input="errors.ru_description = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.price"
                    label="Стоимость"
                    :error-messages="errors.price"
                    @input="errors.price = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.discount_price"
                    label="Скидка"
                    :error-messages="errors.discount_price"
                    @input="errors.discount_price = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-checkbox
                    v-model="dialogData.is_combo"
                    label="Комбо"
                    color="success"
                    hide-details
                    :error-messages="errors.is_combo"
                    @change="errors.is_combo = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-file-input
                    v-model="dialogData.img"
                    prepend-icon="mdi-camera"
                    label="Картинка"
                    :error-messages="errors.img"
                    @change="errors.img = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-file-input
                    v-model="dialogData.primary_img"
                    prepend-icon="mdi-camera"
                    label="Промо картинка"
                    :error-messages="errors.primary_img"
                    @change="errors.primary_img = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.category_id"
                    :items="goodsCtegoriesList"
                    item-text="name"
                    item-value="id"
                    label="Категория"
                    :error-messages="errors.category_id"
                    @change="errors.category_id = []"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>
          <v-btn
            v-if="actionMethod === 'edit'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="editGoods"
          >
            Редактировать
          </v-btn>
          <v-btn
            v-if="actionMethod === 'create'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="createGoods"
          >
            Создать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5"> Вы действительно хотите удалить?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
          >
            Отменить
          </v-btn>
          <v-btn
            color="danger darken-1"
            text
            @click="deleteGoods"
          >
            Удалить
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Корзина товаров -->
    <v-dialog
      v-model="isShowCart"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card md="12">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="isShowCart = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Коризна </v-toolbar-title>
          <p style="text-align: center; margin: 0 0 0 15px">Сумма:20000 тг Доставка: 500тг</p>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="isShowCart = false"
            >
              Сохранить
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-subheader>Информация о клиенте</v-subheader>
          <v-list-item>
            <v-row>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="cartData.first"
                        label="First Name"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="cartData.last"
                        label="Last Name"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="cartData.phone"
                        label="Телефон"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="cartData.address"
                        label="Адрес"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-row>
          </v-list-item>
        </v-list>
        <v-list
          three-line
          subheader
        >
          <v-subheader>Назначить</v-subheader>
          <v-list-item>
            <v-row class="md-12">
              <v-form
                cols="12"
                md="12"
              >
                <v-container lg="12">
                  <v-row md="12">
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <h4 class="mb-2">Город</h4>
                      <v-radio value="Google">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text">Алматы</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="Duckduckgo">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text">Атырау</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <h4 class="mb-2">Точка в горде</h4>
                      <v-select
                        label="Solo field"
                        dense
                        solo
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <h4 class="mb-2">Способ оплаты</h4>
                      <v-radio value="Google">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text">Карта</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="Duckduckgo">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text">Наличными</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <h4 class="mb-2">Способ доставки</h4>
                      <v-radio value="Google">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text">Доставка</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="Duckduckgo">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text">Самовывоз</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-row>
          </v-list-item>
        </v-list>

        <v-list
          three-line
          subheader
        >
          <v-subheader>Товары</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-card
                v-for="item in 4"
                class="mx-auto"
                :key="item"
                max-width="200"
              >
                <v-img
                  class="black--text align-end"
                  height="100px"
                  src="https://api.shoq.kz/storage/goods/December2021/vDpQxlIHQMidYJaCIcLf.png"
                >
                </v-img>
                <v-card-title
                  color="orange text-md-8 mb-4"
                  style="font-size: 12px"
                  >Бургер из капусты</v-card-title
                >
                <!-- <v-card-subtitle class="pb-0"> Цена 1000тг </v-card-subtitle> -->

                <v-card-actions>
                  <v-btn
                    color="orange pb-0"
                    small
                    fab
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    color="orange pb-0"
                    small
                    fab
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>

                  <!-- <v-btn color="orange" text> Explore </v-btn> -->
                </v-card-actions>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import GoodsService from '@/app/Services/GoodsService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'
import GetGoods from '@/app/Helpers/mixins/essences/getGoods'

export default {
  name: 'GoodsDashboard',

  mixins: [TableDialogMixin, GetGoods],

  data() {
    return {
      isShowCart: false,
      cartData: {
        first: 'Arman',
        last: 'Ivanov',
        phone: '888887666',
        address: ' Алматы ул. Абая 33',
      },
      dialogData: {
        ru_name: '',
        ru_description: '',
        price: '',
        discount_price: '',
        is_combo: false,
        img_source: '',
        primary_img_source: '',
        category_id: '',
      },
    }
  },

  computed: {
    goodsCtegoriesList() {
      return this.$store.state.references.itemCategories
    },
  },

  async created() {
    await this.getGoods()
  },

  methods: {
    async createGoods() {
      if (this.$refs.form.validate()) {
        const response = await GoodsService.createGoods(this.dialogData)

        if (response.status) {
          await this.getgoods()
          this.dialog = false
        } else {
          this.errors = response.error
        }
      }
    },

    async editGoods() {
      if (this.$refs.form.validate()) {
        const response = await GoodsService.editGoods(
          this.selectedId,
          this.dialogData,
        )

        if (response.status) {
          await this.getgoods()
          this.dialog = false
        } else {
          this.errors = response.error
        }
      }
    },

    async deleteGoods() {
      const response = await GoodsService.deleteGoods(this.selectedId)

      if (response.status) {
        await this.getgoods()
        this.dialogDelete = false
      } else {
        this.errors = response.error
      }
    },
  },
}
</script>

<style>
.v-card__title {
  font-size: 12px;
  margin-bottom: 8px;
}
</style>
